/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';

import { Home } from '../public/SVG/Home'
import { TituloMenu } from './ItensMenu/TituloMenu'
import { SubTituloMenu } from './ItensMenu/SubTituloMenu'
import MyContext from '../contexts/myContext'
import { Button } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout';
import { api } from '../services/api'

import imgDefault from '../public/PNG/IconeUsuario_Black.png'
import Logo_Inndoor from '../public/PNG/Logo_Inndoor.png'
import IconeSobre from '../public/PNG/IconeSobre.png'
import SetaPNG from '../public/PNG/seta.png'
import { version } from '../services/version';
import { SVGConfig } from '../public/SVG/SVGConfig';
import { SVGDashboard } from '../public/SVG/SVGDashboard';
import { SVGPinON } from '../public/SVG/SVGPinON';
import { SVGPinOFF } from '../public/SVG/SVGPinOFF';






export function MenuLateralExpandido(props) {
  const navigate = useHistory();
  const { user, setUser, tabsAbertas, setTabsAbertas } = useContext(MyContext)
  const { setTabAtual } = useContext(MyContext)
  const [menuDash, setMenuDash] = useState(false)
  const [menuConfig, setMenuConfig] = useState(false)
  const [menuAbout, setMenuAbout] = useState(false)
  const [menuFixed, setMenuFixed] = useState(localStorage.menuFixed == 'true' ? true : false)


  function handleLogout() {
    //handleDownloadPDF()
    //return
    setUser({})
    setTabsAbertas(tabsAbertas.filter(t => t.name === 'OPÇÕES'))
    setTabAtual('')

    localStorage.userMantido = null
    localStorage.manterLogin = false
    navigate.replace(`/`);
  }

  useEffect(() => {
    try {
      const urlImg = `${api.defaults.baseURL}/usuario/api/getimagemusuario/${user?.idUsuario}`
      document.getElementById('imgUser').src = urlImg
    } catch (error) {
      console.log('Erro ao buscar a Imagem do usuario: ' + error)
    }

    if (localStorage?.menuFixed == 'true') {
      props.switchBarraLateral()
    }


  }, [])

  function onMouseEnter() {
  }

  function onMouseLeave() {
    if (menuFixed) {
      return
    }

    props.switchBarraLateral()
  }

  function handleSetMenu() {
    localStorage.menuFixed = !menuFixed
    setMenuFixed(!menuFixed)
  }




  return (

    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        width: props.mobile === true ? '100%' : '200px',
        padding: '0.5rem',

        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        WebkitOverflowScrolling: ''
      }}
      className="bg-gray-800 flex overflow-y-scroll flex-col h-full transition duration-200 md:w-full barMenu "
    >
      <div>
        <div
          //title='Encolher Menu'
          //onClick={props.switchBarraLateral}
          className='py-1 flex md:h-14 2xl:h-16 md:w-full '
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1rem'


          }}
        >

          {props.mobile === true ?
            null
            :
            <>
              <img
                style={{ marginTop: '2.5rem' }}
                id="output" src={Logo_Inndoor}
                className='select-none bg-white-200 p-1 rounded-sm '
              />
              <div onClick={() => handleSetMenu()} className=" mt-[-2.2rem] cursor-pointer ml-[-0.3rem] text-xl flex flex-row items-center w-1/5">
                {
                  menuFixed ?
                    <SVGPinOFF color={'white'} />
                    :
                    <SVGPinON color={'white'} />
                }
              </div>
              {
                /* <ArrowLeft color={'white'} width="1.3em" height="1.3em" /> */
              }
            </>
          }

        </div>
      </div>
      <div className="mt-[1.5rem] flex flex-col h-35 w-full">
        <div className="flex justify-center">
          <img
            style={{
              borderRadius: '50%',
              width: '5rem',
              height: '5rem',
              marginBottom: '0.5rem',
              borderColor: 'white',
              borderWidth: 2
            }}
            id="imgUser"
            src={imgDefault}
            className='select-none '
            onError={(e) => { e.target.onError = null; e.target.src = imgDefault }}
          />
          {// <img className="h-12 w-12 md:h-20 md:w-20 2xl:h-24 2xl:w-24" src="IconeUsuario.png" />
            //<UserDefault id="imgUser" color={'white'} height="5rem" width="5rem" />
          }

        </div>
        <p className="font-semibold text-white-100 text-center text-xs md:text-base 2xl:text-sm mb-1">
          {user.Nome}
        </p>
        <Button
          className="mt-2 mb-2 bg-white-100 ml-9 mr-9  "
          onClick={() => handleLogout()}
          variant="outlined"
          startIcon={<LogoutIcon />}
          style={{ color: 'white', borderColor: 'white' }}

          size='small'
        >
          Sair
        </Button>
      </div>
      <div className="mt-2 flex flex-col h-auto w-full">
        <Link onClick={() => setTabAtual('')} to='/'>
          <div
            style={{
              borderRadius: '0.3rem',
              marginBottom: '0.5rem'
            }}
            className="cursor-pointer p-3 flex flex-row w-full h-10 bg-gray-700 shadow-lg shadow-gray-900 mt-2 hover:bg-gray-500"
          >

            <div className="flex flex-row items-center w-1/5">
              <Home color={'white'} width="1.2em" height="1.2em" />
            </div>
            <TituloMenu Titulo="Página Inicial" />



          </div>
        </Link>

        <div
          style={{
            borderRadius: '0.3rem',
            marginBottom: '0.5rem'
          }}
          className="cursor-pointer p-3 flex flex-row w-full h-10 bg-gray-700 shadow-lg shadow-gray-900 hover:bg-gray-500"
          onClick={() =>
            setMenuDash(!menuDash)}>
          <div className="text-2xl flex flex-row items-center w-1/5">
            <SVGDashboard color={'white'} />
          </div>
          <TituloMenu Titulo="Publicações" />

          <div className="flex items-center justify-end w-1/5">
            {menuDash === false
              ? (
                <img className="select-none h-2 w-2 md:h-3 md:w-3 transition duration-200" src={SetaPNG} />
              )
              : (<img className='select-none h-2 w-2 md:h-3 md:w-3 transition duration-200 rotate-90' src={SetaPNG} />)}
          </div>
        </div>
        {menuDash && (
          <div className='transition duration-500'>
            {props?.listaDepartamentos.length > 0 && props?.listaDepartamentos?.map(dep => {
              return (
                <SubTituloMenu
                  Departamento={dep.Departamento}
                  handleSetMenuExpand={props.mobile === true ? props?.handleSetMenuExpand : () => { }}
                  key={dep.Departamento}
                  Page={'dashpordepartamento'}
                  SubTitulo={dep.Departamento}
                />
              )
            })}
          </div>)}
        <div
          style={{
            borderRadius: '0.3rem',
            marginBottom: '0.5rem',
            display: user.PerfilConfiguracaoAutonomiaBI === 1 || user.GodMode === "true" ? 'flex' : 'none'
          }}
          className="cursor-pointer p-3 flex flex-row w-full h-10 bg-gray-700 shadow-lg shadow-gray-900 hover:bg-gray-500"
          onClick={() => setMenuConfig(!menuConfig)}
        >
          <div className="text-2xl flex flex-row items-center w-1/5">
            <SVGConfig color={'white'} />
          </div>
          <TituloMenu Titulo="Configurações" />

          <div className="flex items-center justify-end w-1/5">
            {menuConfig === false
              ? (
                <img className="select-none h-2 w-2 md:h-3 md:w-3 transition duration-200" src={SetaPNG} />
              )
              : (<img className='select-none h-2 w-2 md:h-3 md:w-3 rotate-90 transition duration-200' src={SetaPNG} />)}
          </div>
        </div>
        {menuConfig && (<div>
          <SubTituloMenu
            handleSetMenuExpand={props.mobile === true ? props?.handleSetMenuExpand : () => { }}
            Page="departamentos"
            SubTitulo="Categorias"
          />
          <SubTituloMenu
            handleSetMenuExpand={props.mobile === true ? props?.handleSetMenuExpand : () => { }}
            Page="cadastrodashboard"
            SubTitulo="Publicações"
          />
          <SubTituloMenu
            handleSetMenuExpand={props.mobile === true ? props?.handleSetMenuExpand : () => { }}
            Page="CadastroAgendamentos"
            SubTitulo="Agendamentos"
          />



          {user.GodMode === "true" &&
            <>
              <SubTituloMenu
                handleSetMenuExpand={props.mobile === true ? props?.handleSetMenuExpand : () => { }}
                Page="cadastroempresas"
                SubTitulo="Empresas"
              />

              <SubTituloMenu
                handleSetMenuExpand={props.mobile === true ? props?.handleSetMenuExpand : () => { }}
                Page="cadastrousuarios"
                SubTitulo="Usuários"
              />
            </>
          }


        </div>)}

      </div>

      <div
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
          color: 'white',
          fontWeight: 'bold',
          paddingBottom: '1rem',
          fontSize: '12px',
          marginTop: '2rem'
        }}
      >
        Versão {version}
      </div>
    </div>
  )
}
