import React, { useEffect, useContext, useState, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react'



import { api } from '../../services/api'
import MyContext from '../../contexts/myContext'


import IframeDashURL from '../../components/IframeDashURL'

import { BarraInformacoes } from '../../components/BarraInformacoes'

import ReturnDashVideo from './components/ReturnDashVideo'

export default function Carrossel(props) {
    const { user } = useContext(MyContext)
    const containerRef = useRef(null);

    const [controls, setControls] = useState(true)

    const handleToggleFullscreen = () => {
        if (containerRef.current) {
            if (!document.fullscreenElement) {
                containerRef.current.requestFullscreen().catch((err) => {
                    console.error(`Erro ao tentar habilitar o modo de tela cheia: ${err.message} (${err.name})`);
                });
            } else {
                document.exitFullscreen();
            }
        }
    };

    const [isLoading, setisLoading] = useState(false)
    const [dadosDash, setDadosDash] = useState([])
    const [ExibirBarraInformacoes, setExibirBarraInformacoes] = useState(0)
    const [ComposicaoCarrossel, setComposicaoCarrossel] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0);
    const [secondsRemaining, setSecondsRemaining] = useState(0);
    const [showControls, setShowControls] = useState(true);

    const [isPlaying, setIsPlaying] = useState(true); // Adiciona um estado para controlar se o intervalo está rodando ou pausado


    const params = useParams()

    const vNomeAmigavel = params.NomeAmigavel || props.NomeAmigavel
    const vNomeInterno = params.NomeInterno || props.NomeInterno


    const { setHidebar, barraLateral, hideBar } = useContext(MyContext)

    useEffect(() => {
        if (params?.hideBar === "true") {
            setHidebar(true)
        }
    }, [])


    const getDadosDashBoard = async () => {
        await api
            .get(`/dashboard/api/listaDashboard/${user.cnpj}`)
            .then(data => {
                setDadosDash(data.data)
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        getDadosDashBoard()
    }, [])

    useEffect(async () => {
        if (dadosDash.length > 0) {

            const myDadosDash = dadosDash.filter(dash => dash.Descricao === vNomeInterno)

            if (myDadosDash.length > 0) {
                console.log('myDadosDash')
                console.log(myDadosDash)
                setExibirBarraInformacoes(parseInt(myDadosDash[0].ExibirBarraInformacoes))
                await getComposicaoCarrossel(parseInt(myDadosDash[0].Id))
            }
        }
    }, [dadosDash, vNomeInterno])


    const getComposicaoCarrossel = async (IdDash) => {
        try {
            setisLoading(true)

            await api.get(`/dashboard/api/listaComposicaoCarrossel/${user.cnpj}/${IdDash}`)
                .then(data => {
                    const results = data.data
                    setComposicaoCarrossel(results)

                })
                .catch(error => {
                    console.log('Erro em getComposicaoCarrossel')
                    console.log(error)
                })
        } catch (error) {
            console.log('erro em getRegistrosBD.')
            console.log(error)
        }
        finally {
            setisLoading(false)
        }
    }



    function ReturnDashLink(vCarrossel) {
        if (vCarrossel) {
            return (
                <div className={`${hideBar ? 'h-[100vh]' : 'h-[calc(100vh-3.8rem)]'}   w-full`}>
                    <IframeDashURL URL={vCarrossel.LinkSelecionado} />
                </div>
            )
        }
    }





    function ReturnDashFoto(vCarrossel) {
        if (vCarrossel) {
            return (
                <div style={{ width: '100%', maxWidth: '100%', maxHeight: hideBar ? '100vh' : '92vh' }}>
                    <img
                        style={{ width: '100%', maxHeight: hideBar ? '100vh' : '92vh' }}
                        src={`${api.defaults.baseURL}/dashboard/api/fotocarrossel/${vCarrossel.Exibindo}`}
                        alt="Imagem Carrossel"
                    />
                </div>
            )
        }
    }

    const renderedCarrossel = useMemo(() => TrataRenderCarrossel(ComposicaoCarrossel, currentIndex), [ComposicaoCarrossel, currentIndex]);

    function TrataRenderCarrossel(Carrossel, currentIndex) {

        if (!Carrossel) {
            return <></>
        }

        if (Carrossel.length === 0) {
            return <></>
        }

        let vCarrossel = Carrossel[currentIndex]

        if (vCarrossel.Tipo === 'LINK') {
            return ReturnDashLink(vCarrossel)
        }
        if (vCarrossel.Tipo === 'VÍDEO') {
            return <ReturnDashVideo controls={controls} vCarrossel={vCarrossel} hideBar={hideBar} />
        }
        if (vCarrossel.Tipo === 'IMAGEM') {
            return ReturnDashFoto(vCarrossel)
        }
    }

    useEffect(() => {
        if (ComposicaoCarrossel.length > 0 && isPlaying) {
            const vDuracao = ComposicaoCarrossel[currentIndex].MinutosDuracao;
            let minutos, segundos;

            if (typeof vDuracao === 'string' && vDuracao.includes('.')) {
                [minutos, segundos] = vDuracao.split('.').map(Number);
            } else {
                minutos = parseInt(vDuracao);
                segundos = 0;
            }

            const duracaoEmSegundos = isNaN(segundos) ? minutos : minutos * 60 + segundos;

            if (duracaoEmSegundos > 0) {
                const interval = setInterval(() => {
                    const nextIndex = (currentIndex + 1) % ComposicaoCarrossel.length;
                    setCurrentIndex(nextIndex);
                }, duracaoEmSegundos * 1000); // Convertendo para milissegundos

                return () => clearInterval(interval);
            }
        }
    }, [ComposicaoCarrossel, currentIndex, isPlaying])




    const handleTogglePlay = () => {
        setIsPlaying(!isPlaying);
    };

    const handlePrev = () => {
        const prevIndex = (currentIndex - 1 + ComposicaoCarrossel.length) % ComposicaoCarrossel.length;
        setCurrentIndex(prevIndex);
    };

    const handleNext = () => {
        const nextIndex = (currentIndex + 1) % ComposicaoCarrossel.length;
        setCurrentIndex(nextIndex);
    };


    useEffect(() => {
        let timeout;

        const handleMouseMove = () => {
            setShowControls(true);
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                setShowControls(false);
            }, 5000);
        };

        const handleClick = () => {
            setShowControls(true);
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                setShowControls(false);
            }, 5000);
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('click', handleClick);
            clearTimeout(timeout);
        };
    }, []);




    return (
        <div ref={containerRef} className={`z-[2] bg-gray-100 w-full h-full  `}>
            {showControls && (
                <div className='absolute right-4 top-2 z-50 p-1  flex flex-row bg-gradient-to-r from-[#585858] from to-[#2c2525] rounded-xl border-[1px] border-white-200 gap-[1.5rem]  items-end justify-end' >
                    <div onClick={handlePrev} className=" select-none cursor-pointer text-xs border-[2px] border-white-200 rounded-[50%] justify-center items-center p-1 text-white-200 transition duration-100 hover:text-red-400 hover:border-red-400">
                        <FeatherIcon
                            className={''}
                            icon={'chevrons-left'}
                            size={14}
                        />
                    </div>
                    <div onClick={handleTogglePlay} className="select-none cursor-pointer text-xs border-[2px] border-white-200 rounded-[50%] justify-center items-center p-1 text-white-200 transition duration-100 hover:text-red-400 hover:border-red-400">
                        <FeatherIcon
                            className={''}
                            icon={isPlaying === true ? 'pause' : 'play'}
                            size={14}
                        />
                    </div>
                    <div onClick={handleNext} className="select-none cursor-pointer text-xs border-[2px] border-white-200 rounded-[50%] justify-center items-center p-1 text-white-200 transition duration-100 hover:text-red-400 hover:border-red-400">
                        <FeatherIcon
                            className={''}
                            icon={'chevrons-right'}
                            size={14}
                        />
                    </div>
                    <div onClick={handleToggleFullscreen} className="select-none cursor-pointer text-xs border-[2px] border-white-200 rounded-[50%] justify-center items-center p-1 text-white-200 transition duration-100 hover:text-red-400 hover:border-red-400">
                        <FeatherIcon
                            className={''}
                            icon={'maximize'}
                            size={14}
                        />
                    </div>
                    <div onClick={() => {
                        setControls(!controls)
                    }} className="select-none cursor-pointer text-xs border-[2px] border-white-200 rounded-[50%] justify-center items-center p-1 text-white-200 transition duration-100 hover:text-red-400 hover:border-red-400">
                        <FeatherIcon
                            className={''}
                            icon={controls ? 'lock' : 'unlock'}
                            size={14}
                        />
                    </div>
                </div>
            )}

            {dadosDash.length > 0 ?
                renderedCarrossel
                :
                null
            }

            {ExibirBarraInformacoes ?
                <BarraInformacoes />
                :
                null
            }

        </div >
    )
}
