import { useContext, useEffect } from 'react'


import { Home } from '../public/SVG/Home'

import MyContext from '../contexts/myContext'

import LogoInntegraSmall from '../public/PNG/Logo_Inndoor_Small.png'
import imgDefault from '../public/PNG/IconeUsuario_Black.png'

import IconeSobre from '../public/PNG/IconeSobre.png'
import { api } from '../services/api'
import { version } from '../services/version'
import { SVGConfig } from '../public/SVG/SVGConfig'
import { SVGDashboard } from '../public/SVG/SVGDashboard'



export function MenuLateralEncolhido(props) {
    const { user } = useContext(MyContext)

    function onMouseEnter() {

        props.switchBarraLateral()
    }

    function onMouseLeave() {


    }

    useEffect(() => {
        try {
            const urlImg = `${api.defaults.baseURL}/usuario/api/getimagemusuario/${user?.idUsuario}`
            document.getElementById('imgUserEncolhido').src = urlImg
        } catch (error) {
            console.log('Erro ao buscar a Imagem do usuario: ' + error)
        }

    }, [])

    return (
        <div
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className="w-14 h-full bg-gray-800 flex flex-col transition duration-200"
        >
            <div
                title='Expandir Menu'
                style={{
                    paddingTop: '1rem',
                    paddingBottom: '1rem'
                }}
                onClick={props.switchBarraLateral} className="flex justify-center cursor-pointer">
                <img className="h-8 w-8" src={LogoInntegraSmall} alt='LogoInntegraSmall' />
            </div>

            <div className="flex justify-center">
                <img
                    style={{
                        borderRadius: '50%',
                        width: '2.2rem',
                        height: '2.2rem',
                        borderColor: 'white',
                        borderWidth: 2,
                        marginBottom: '7.5rem'
                    }}
                    id="imgUserEncolhido"
                    src={imgDefault}
                    onError={(e) => { e.target.onError = null; e.target.src = imgDefault }}
                    alt='imgUserEncolhido'
                />

            </div>

            <div className="mt-2 flex flex-col h-auto w-full">
                <div
                    style={{
                        borderRadius: '0.5rem',
                        margin: '0.3rem',
                        padding: '0.5rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                    className="bg-gray-700 shadow-lg">
                    <Home color={'white'} width="1.2em" height="1.2em" />
                </div>

                <div
                    style={{
                        borderRadius: '0.5rem',
                        margin: '0.3rem',
                        padding: '0.5rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                    className="bg-gray-700 shadow-lg">
                    <div className="text-2xl">
                        <SVGDashboard color={'white'} />
                    </div>
                </div>

                <div
                    style={{
                        borderRadius: '0.5rem',
                        margin: '0.3rem',
                        padding: '0.5rem',
                        display: user.PerfilConfiguracaoAutonomiaBI === 1 || user.GodMode === "true" ? 'flex' : 'none',
                        alignItems: 'center',
                        justifyItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                    className="bg-gray-700 shadow-lg">
                    <div className="text-2xl">
                        <SVGConfig color={'white'} />
                    </div>
                </div>

            </div>
            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    color: 'white',
                    fontWeight: 'bold',
                    paddingBottom: '1rem',
                    fontSize: '11px',
                    marginTop: '2rem'
                }}
            >
                v{version}
            </div>

        </div>
    )
}